/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/tables';
@import '@porsche-design-system/components-js/utilities/scss.scss';

html,
body {
  font-family: 'Porsche Next', sans-serif;
}

.footer {
  background-color: #191f22;
  color: #fff;

  form & {
    padding: 1rem 0;
  }

  img {
    max-height: 250px;
    width: 100%;
    object-fit: cover;
  }
}

img {
  max-width: 100%;
}

.crest.sc-phn-crest {
  display: inline-block;
  box-sizing: border-box;
  background-color: #fff;
  border: 0.0625rem solid #c9cacb;
  border-top: 0;
  height: 4.8125rem;
  padding: 0.3125rem 0.1875rem 0.5625rem 0.1875rem;
  margin-left: 3rem;
  margin-bottom: -0.75rem;
}

@media (min-width: 1760px) {
  .crest.sc-phn-crest {
    height: 8.5625rem;
    padding: 1.8125rem 0.375rem 2.0625rem 0.375rem;
  }
}

@media (min-width: 1300px) {
  .crest.sc-phn-crest {
    height: 8.3125rem;
    padding: 1.75rem 0.25rem 1.875rem 0.25rem;
    margin-bottom: -0.9375rem;
  }
}

@media (min-width: 760px) {
  .crest.sc-phn-crest {
    height: 5.25rem;
    padding: 0.5625rem 0.25rem 0.8125rem 0.25rem;
  }
}

.position-relative {
  position: relative;
}

.height-vh100 {
  min-height: 100vh;
}

p-fieldset-wrapper p-grid-item,
.mb-16 {
  margin-bottom: 1rem;
}

p-fieldset-wrapper {
  margin: 2rem auto;
}

#survey-wrapper {
  width: 66%;
  padding: 2rem;
  border: 1px solid #e3e4e5;

  @media screen and (max-width: 1050px) {
    width: 100%;
    padding: 0;
    border-width: 0;
  }
}

.sub-header {
  background-position-y: 75%;
  background-size: cover;
  background-repeat: no-repeat;

  @include pds-media-query-min-max('xxs', 'xs') {
    height: 190px;
    background-image: url('./assets/hero-s.jpg');
  }

  @include pds-media-query-min-max('xs', 's') {
    height: 320px;
    background-image: url('./assets/hero-s.jpg');
  }

  @include pds-media-query-min-max('s', 'm') {
    height: 360px;
    background-image: url('./assets/hero-m.jpg');
  }

  @include pds-media-query-min-max('m', 'l') {
    height: 460px;
    background-image: url('./assets/hero-m.jpg');
  }

  @include pds-media-query-min-max('l', 'xl') {
    height: 568px;
    background-image: url('./assets/hero.jpg');
  }

  @include pds-media-query-min('xl') {
    height: 720px;
    background-image: url('./assets/hero.jpg');
  }
}

.offer-preview-header {
  bottom: 4rem;
  position: relative;

  @include pds-media-query-min-max('xxs', 's') {
    bottom: 40px;
  }
}

.offer-preview-content {
  margin-bottom: 2rem;

  @include pds-media-query-min-max('xxs', 's') {
    margin-top: -2rem;
  }
}

.form-label {
  padding: 0 0 0.5rem;
}

.spinner-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.helion-logo {
  height: 5rem;
  padding-left: 1rem;
  @include pds-media-query-min('l') {
    max-height: 60px;
  }
  @include pds-media-query-max('s') {
    padding-left: 2rem;
  }
}

section {
  margin-bottom: 3rem;
}

.h-100 {
  height: 100%;
}
